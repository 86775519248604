.btn-style {
  width: 174px;
  height: 40px;
  background: #00285f;
  border-color: #00285f;
  border-radius: 5px;
}

.btn-style h6 {
  font-size: 14px;
  color: #FFF;
  font-weight: 700;
}
