.nav-logo-text {
  color: #00285f;
  size: 24px;
}

.main-nav {
  min-height: 70px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-menu {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  width: 280px;
}

.main-menu a {
  font-weight: 400;
  margin-right: 70px;
}

.main-menu a:hover,
.main-menu .active {
  font-weight: 700;
}

@media (min-width: 768px) {
  .main-nav div {
    display: flex;
    justify-content: flex-end;
  }
  .main-menu a {
    margin: 0 20px 0 30px;
  }
}
