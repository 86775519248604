.home-container {
  padding: 20px 10px;
}

.home-card {
  background-color: #00285f;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
}

.home-image-container {
  margin: 62px 28px 35px 28px;
  width: 244px;
  height: 146px;
}

.home-image-containe svg {
  width: 100%;
  height: auto;
}

.home-content-container {
  padding: 20px;
}

.home-content-container h1 {
  font-size: 22px;
}

.home-content-container p {
  margin-top: 13px;
  font-size: 14px;
  line-height: 16px;
}

.home-painel-container {
  margin-top: 20px;
  text-align: center;
  padding: 28px;
}

.home-painel-container p {
  margin-top: 10px;
  margin-bottom: 0;
  color: #585858;
}

@media (min-width: 576px) {
  .home-container {
    padding: 20px;
  }

  .home-image-container {
    width: 333px;
    height: 199px;
  }

  .home-image-container svg {
    width: 100%;
    height: auto;
  }

  .home-content-container {
    padding: 0 0 42px 0;
    width: 437px;
  }
  .home-content-container h1 {
    font-size: 36px;
  }
  .home-content-container p {
    margin-top: 13px;
    font-size: 18px;
  }
  .home-painel-container {
    display: flex;
    flex-direction: row;
    padding: 42px 62px 42px 85px;
  }
  .home-painel-container p {
    margin-left: 16px;
  }
}

@media (min-width: 992px) {
  .home-image-container {
    width: 455px;
    height: 272px;
  }

  .home-image-container svg {
    width: 100%;
    height: auto;
  }

  .home-content-container {
    margin-bottom: 40px;
  }
}

@media (min-width: 1280px) {
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .main-nav div,
  .home-container {
    width: 979px;
  }

  .home-card {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  .home-image-container {
    width: 333px;
    height: 199px;
  }
  .home-content-container p,
  .home-content-container h1 {
    margin: 0;
    padding: 0;
    text-align: start;
  }

  .home-content-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .main-nav div a {
    width: 200px;
  }
}
