.product-card {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.card-top-container img {
  width: 100%;
  height: auto;
}

.card-botton-container h6 {
  text-align: center;
  color: #000;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.card-botton-container p {
  text-align: left;
  margin-bottom: 30px;
  color: #585858;
  ;
}

@media (min-width: 576px) {
  .card-botton-container p {
    font-size: 12px;
  }
}